<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_analysis_sys_page relative w1240">
			<div class="analysis_sys_category_con">
				<div class="analysis_sys_category_title fontsize22">行业分类</div>
				<div class="analysis_sys_category_list">
					<div class="ul list1">
						<div class="li item1" :class="{'isopen':item1.isopen}" v-for="(item1,index1) in industryType" :key="index1">
							<div class="menu_title fontsize14">
								<span class="span">{{item1.name}}</span>
								<div class="img" v-if="item1.children&&item1.children.length>0" @click="openindustryType(index1,null)">
									<img v-if="!item1.isopen" src="../assets/static/icon_add.png"/>
									<img v-if="item1.isopen" src="../assets/static/icon_reduce.png"/>
								</div>
							</div>
							<div ref="list2" class="ul list2 collapse-transition" v-if="item1.children&&item1.children.length>0">
								<div class="li item2" :class="{'isopen':item2.isopen}" v-for="(item2,index2) in item1.children" :key="index2">
									<div class="menu_title fontsize14 menu2_title">
										<span class="span">{{item2.name}}</span>
										<div class="img" v-if="item2.children&&item2.children.length>0" @click="openindustryType(index1,index2)">
											<img v-if="!item2.isopen" src="../assets/static/icon_add.png"/>
											<img v-if="item2.isopen" src="../assets/static/icon_reduce.png"/>
										</div>
									</div>
									<div :ref="'list3_'+index1+'_'+index2" class="ul list3 collapse-transition" v-if="item2.children&&item2.children.length>0">
										<div class="li item3" :class="{'isopen':item3.isopen}" v-for="(item3,index3) in item2.children" :key="index3">
											<div class="menu_title fontsize14 menu3_title">
												<span class="span">{{item3.name}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="analysis_sys_page_con">
				<!-- <div class="block20"></div> -->
				<!-- <div class="analysis_sys_page_title fontsize18">高级检索</div> -->
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">号码</div>
					<div class="select_one">
						<el-select v-model="haomaType" multiple placeholder="请选择申请号/公开号/授权号(可多选)">
						    <el-option v-for="item in haoma" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input">
							<el-input v-model="haomaVaule" placeholder="请输入内容"></el-input>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">日期</div>
					<div class="select_one">
						<el-select v-model="riqiType" multiple placeholder="请选择申请日/公开日/授权公告日(可多选)">
						    <el-option v-for="item in riqi" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="input_samll">
						<el-select v-model="formobj.beforeFlag" placeholder="请选择以前/以后">
						    <el-option label="某日期以前" :value="0"></el-option>
							<el-option label="某日期以后" :value="1"></el-option>
						</el-select>
					</div>
					<div class="input_center">
						<el-date-picker type="date" placeholder="请选择日期" v-model="riqiVaule" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">关键词</div>
					<div class="select_one">
						<el-select v-model="keywordType" multiple placeholder="请选择标题/摘要/权利要求书(可多选)">
						    <el-option v-for="item in keywordList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input" v-for="(item,index) in keywordVaule" :key="index">
							<el-input v-model="item.value" placeholder="请输入内容"></el-input>
							<div v-if="index<keywordVaule.length-1" @click="delkeyword(index)" class="editicon"><img src="../assets/static/yx_close_red.png"/></div>
							<div v-else class="editicon" @click="addkeyword"><img src="../assets/static/add_icon.png"/></div>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">名称</div>
					<div class="select_one">
						<el-select v-model="mingchengType" multiple placeholder="请选择申请人/发明(设计)人/专利权人/受让人/转让人(可多选)">
						    <el-option v-for="item in mingchengList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input" v-for="(item,index) in mingchengVaule" :key="index">
							<el-input v-model="item.value" placeholder="请输入内容"></el-input>
							<div v-if="index<mingchengVaule.length-1" @click="delmingcheng(index)" class="editicon"><img src="../assets/static/yx_close_red.png"/></div>
							<div v-else class="editicon" @click="addmingcheng"><img src="../assets/static/add_icon.png"/></div>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">代理</div>
					<div class="select_one">
						<el-select v-model="dailiType" multiple placeholder="请选择代理机构/代理人(可多选)">
						    <el-option v-for="item in daili" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input">
							<el-input v-model="dailiVaule" placeholder="请输入内容"></el-input>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">地址</div>
					<div class="select_one">
						<el-select v-model="addressType" multiple placeholder="请选择申请人地址/申请人国家/公开国(可多选)">
						    <el-option v-for="item in addressList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input">
							<el-input v-model="addressVaule" placeholder="请输入内容"></el-input>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize14">
					<div class="titlediv">分类号</div>
					<div class="select_one">
						<el-select v-model="formobj.mainClassNo" placeholder="请选择分类号">
							<el-option label="全部" :value="null"></el-option>
						    <el-option v-for="item in fenleiList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="inputdiv">
						<div class="input" v-for="(item,index) in fenleiVaule" :key="index">
							<el-input v-model="item.value" placeholder="请输入内容"></el-input>
							<div v-if="index<fenleiVaule.length-1" @click="delfenlei(index)" class="editicon"><img src="../assets/static/yx_close_red.png"/></div>
							<div v-else class="editicon" @click="addfenlei"><img src="../assets/static/add_icon.png"/></div>
						</div>
					</div>
				</div>
				<div class="btnlist fontsize12">
					<div class="btn_clear" @click="clickClearAll">清除/重置</div>
					<div class="btn_click" @click="initData">查询检索</div>
					<div class="btn_fenxi">
						<img class="mixicon" src="../assets/static/Frame2035.png"/>
						<div class="text fontsize12">专利分析</div>
					</div>
					<div class="btn_lineSel">
						<el-dropdown trigger="click">
						    <div class="text">
						    	<img class="mixicon" src="../assets/static/Frame2036.png"/>
						    	<div class="span fontsize12">列表显示</div>
								<img class="mixicon" src="../assets/static/Frame2037.png"/>
						    </div>
						    <el-dropdown-menu slot="dropdown">
						      <el-dropdown-item command="line">列表显示</el-dropdown-item>
						    </el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!-- 内容 -->
				<div class="analysis_sys_datalist" v-if="showType!='line'">
					<div class="dataobj pointer" v-for="(item,index) in dataList" :key="index">
						<div class="img" @click="$util.routerPath('/analysisSysDetail',{uid:item.id})">
							<img :src="item.imgUrl || require('../assets/static/no_img.png')"/>
						</div>
						<div class="conbody fontsize14">
							<div @click="$util.routerPath('/analysisSysDetail',{uid:item.id})">
								<div class="titleName fontsize16">{{item.title}}</div>
								<div class="con1">
									<div class="textdiv">
										<div class="text1">公开（公告）号：</div>
										<div class="text2">{{item.openNo}}</div>
									</div>
									<div class="textdiv">
										<div class="text1">申请（专利权）人：</div>
										<div class="text2">{{item.applicant}}</div>
									</div>
								</div>
								<div class="con2">
									<div class="text1">分类号：</div>
									<div class="textdiv">
										<div class="text2" v-for="(item,index) in item.classNoList" :key="index">{{item}}，</div>
									</div>
								</div>
								<div class="con2">
									<div class="text1">发明（设计）人：</div>
									<div class="textdiv">
										<div class="text2">{{item.inventor}}</div>
									</div>
								</div>
								<div class="con1">
									<div class="textdiv1">
										<div class="text1">主分类号：</div>
										<div class="text2">{{item.mainClassNo}}</div>
									</div>
									<div class="textdiv1">
										<div class="text1">申请日：</div>
										<div class="text2">{{item.applyDate}}</div>
									</div>
									<div class="textdiv1">
										<div class="text1">公开（公告）日：</div>
										<div class="text2">{{item.openDate}}</div>
									</div>
								</div>
							</div>
							<div class="con3">
								<div class="textdiv">
									<div class="text1">摘要：</div>
									<div class="text2" :class="{'clamp':!item.isShowDesc}">{{item.description}}</div>
								</div>
								<div class="more">
									<div class="text1"></div>
									<div class="text2" @click="handleShowDesc(index)" v-if="!item.isShowDesc">展开摘要</div>
									<div class="text2" @click="handleShowDesc(index)" v-if="item.isShowDesc">收起摘要</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="block20"></div>
				<!-- 内容，列表 -->
				<div class="analysis_sys_datalist" v-if="showType=='line'">
					<div class="thlist fontsize16">
						<div class="th1">序号</div>
						<div class="th2">公开(公告)日</div>
						<div class="th3">标题</div>
						<div class="th4">公开(公告)号</div>
						<div class="th5">申请(专利权)人</div>
						<div class="th6">代理人</div>
					</div>
					<div class="tdlist">
						<div class="tditem fontsize12" v-for="(item,index) in dataList" :key="index" @click="$util.routerPath('/analysisSysDetail',{uid:item.id})">
							<div class="clamp td1">{{item.id}}</div>
							<div class="clamp td2"><span></span>{{item.openDate}}</div>
							<div class="td3"><div class="clamp titleName">{{item.title}}</div><span>审中</span></div>
							<div class="clamp td4">{{item.openNo}}</div>
							<div class="clamp td5">{{item.applicant}}</div>
							<div class="clamp td6">{{item.agent}}</div>
						</div>
					</div>
				</div>
				<div class="block30" v-if="dataList.length<=0"></div>
				<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="phonenone" style="text-align:right">
				    <el-pagination
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next, jumper"
				          :total="total">
				    </el-pagination>
				</div>
				<div class="pcnone" style="text-align:right">
				    <el-pagination
					      small
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next"
				          :total="total">
				    </el-pagination>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			keyword:"",
			formobj:{
				beforeFlag:0,//日期查询区间 0以前 1以后
				mainClassNo:null,//主分类号
				typeId:null,//数据库类型ID
			},
			haoma:[
				{
				   value: '申请号',
				   label: '申请号'
				},
				{
				   value: '公开号',
				   label: '公开号'
				},
				{
				   value: '授权号',
				   label: '授权号'
				}
			],
			haomaType:["申请号","公开号","授权号"],
			haomaVaule:"",
			riqi:[
				{
				   value: '申请日',
				   label: '申请日'
				},
				{
				   value: '公开日',
				   label: '公开日'
				},
				{
				   value: '授权公告日',
				   label: '授权公告日'
				}
			],
			riqiType:["申请日","公开日","授权公告日"],
			riqiVaule:"",
			keywordList:[
				{
				   value: '标题',
				   label: '标题'
				},
				{
				   value: '摘要',
				   label: '摘要'
				},
				{
				   value: '权利要求书',
				   label: '权利要求书'
				}
			],
			keywordType:["标题","摘要","权利要求书"],
			keywordVaule:[{value: ''}],
			mingchengList:[
				{
				   value: '申请人',
				   label: '申请人'
				},
				{
				   value: '发明(设计)人',
				   label: '发明(设计)人'
				},
				{
				   value: '专利权人',
				   label: '专利权人'
				},
				{
				   value: '受让人',
				   label: '受让人'
				},
				{
				   value: '转让人',
				   label: '转让人'
				}
			],
			mingchengType:["申请人","发明(设计)人","专利权人","受让人","转让人"],
			mingchengVaule:[{value: ''}],
			daili:[
				{
				   value: '代理机构',
				   label: '代理机构'
				},
				{
				   value: '代理人',
				   label: '代理人'
				}
			],
			dailiType:["代理机构","代理人"],
			dailiVaule:"",
			addressList:[
				{
				   value: '申请人地址',
				   label: '申请人地址'
				},
				{
				   value: '申请人国家',
				   label: '申请人国家'
				},
				{
				   value: '公开国',
				   label: '公开国'
				}
			],
			addressType:["申请人地址","申请人国家","公开国"],
			addressVaule:"",
			fenleiList:[],
			fenleiVaule:[{value: ''}],
			showType:"line",//展示形式，行列 非图文
			industryType:[
				{
					id:1,
					name:"建筑建材",
					isopen:false,
					children:[
						{
							id:11,
							name:"建筑结构",
							isopen:false,
						},
						{
							id:12,
							name:"建筑装饰",
							isopen:false,
							children:[
								{
									id:121,
									name:"第三级1",
								},
								{
									id:122,
									name:"第三级2",
								}
							]
						},
						{
							id:13,
							name:"建筑施工",
							isopen:false,
						}
					]
				},
				{
					id:2,
					name:"家居用品",
					isopen:false,
					children:[
						{
							id:21,
							name:"桌椅成功",
							isopen:false,
						},
						{
							id:22,
							name:"床柜地板",
							isopen:false,
						}
					]
				}
			],
			liConHeight: 0, // 折叠面板内容初始高度
			liConHeight3: 0, // 折叠面板内容初始高度
		};
	},
	mounted: function() {
		if(this.$route.query){
			if(this.$route.query.key){
				//console.log(this.$route.query.key)
				this.keyword = this.$route.query.key
				this.keywordVaule = [{value: this.keyword}]
			}
			if(this.$route.query.typeId){
				this.formobj.typeId = parseInt(this.$route.query.typeId)
			}
		}
		this.pageMainClassNo()
		this.initData()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//展开行业分类
		openindustryType(index1,index2){
			console.log(index1,index2)
			if(index2!=null&&index2>-1){
				//点击二级
				const isopen = this.industryType[index1].children[index2].isopen
				this.industryType[index1].children[index2].isopen = !isopen
				const liCon3 = this.$refs[`list3_${index1}_${index2}`][0]
				var height3 = liCon3.offsetHeight
				if (height3 === this.liConHeight3) { // 展开
				    liCon3.style.height = 'auto'
				    height3 = liCon3.offsetHeight
				    liCon3.style.height = this.liConHeight3 + 'px'
				    // eslint-disable-next-line no-unused-vars
				    let f = document.body.offsetHeight // 必加
				    liCon3.style.height = height3 + 'px'
				} else { // 收缩
				    liCon3.style.height = this.liConHeight3 + 'px'
				}
			}else{
				//点击一级
				const isopen = this.industryType[index1].isopen
				this.industryType[index1].isopen = !isopen
				const liCon = this.$refs.list2[index1]
				var height = liCon.offsetHeight
				//console.log(height)
				if (height === this.liConHeight) { // 展开
				    liCon.style.height = 'auto'
				    height = liCon.offsetHeight
				    liCon.style.height = this.liConHeight + 'px'
				    // eslint-disable-next-line no-unused-vars
				    const f = document.body.offsetHeight // 必加
				    liCon.style.height = height + 'px'
					setTimeout(function(){
						liCon.style.height = 'auto'
					},300)
				} else { // 收缩
				    console.log("收缩")
					if(height>0){
						liCon.style.height = height + 'px'
					}
					const f = document.body.offsetHeight // 必加
				    liCon.style.height = this.liConHeight + 'px'
				}
			}
		},
		//清除重置
		clickClearAll(){
			this.$router.push({ path: '/analysis_sys_page'})
			this.$router.go(0);
		},
		//处理对象
		initData(){
			//号码是否填写
			this.formobj["applyNo"] = null
			this.formobj["openNo"] = null
			this.formobj["authorNo"] = null
			if(this.haomaVaule){
				if(this.haomaType.length>0){
					if(this.haomaType.indexOf("申请号")>-1){
						this.formobj["applyNo"] = this.haomaVaule
					}
					if(this.haomaType.indexOf("公开号")>-1){
						this.formobj["openNo"] = this.haomaVaule
					}
					if(this.haomaType.indexOf("授权号")>-1){
						this.formobj["authorNo"] = this.haomaVaule
					}
				}else{
					this.$message.error("请在号码选择申请号/公开号/授权号(可多选)")
					return;
				}
			}
			//日期是否填写
			this.formobj["applyDate"] = null
			this.formobj["openDate"] = null
			this.formobj["authorDate"] = null
			if(this.riqiVaule){
				if(this.riqiType.length>0){
					if(this.riqiType.indexOf("申请日")>-1){
						this.formobj["applyDate"] = this.riqiVaule
					}
					if(this.riqiType.indexOf("公开日")>-1){
						this.formobj["openDate"] = this.riqiVaule
					}
					if(this.riqiType.indexOf("授权公告日")>-1){
						this.formobj["authorDate"] = this.riqiVaule
					}
				}else{
					this.$message.error("请在日期选择申请日/公开日/授权公告日(可多选)")
					return;
				}
			}
			//关键词是否填写
			this.formobj["title"] = null
			this.formobj["patentBody"] = null //摘要（详细信息）
			this.formobj["rightDocument"] = null //权利要求书
			if(this.keywordVaule.length>0){
				var inputkeyword = []
				for(let i=0;i<this.keywordVaule.length;i++){
					if(this.keywordVaule[i].value){
						inputkeyword.push(this.keywordVaule[i].value)
					}
				}
				if(this.keywordVaule.length>1&&inputkeyword.length<this.keywordVaule.length){
					this.$message.error("请输入内容到关键词的所有输入框")
					return;
				}
				if(inputkeyword.length==this.keywordVaule.length){
					if(this.keywordType.length>0){
						if(this.keywordType.indexOf("标题")>-1){
							this.formobj["title"] = inputkeyword
						}
						if(this.keywordType.indexOf("摘要")>-1){
							this.formobj["patentBody"] = inputkeyword
						}
						if(this.keywordType.indexOf("权利要求书")>-1){
							this.formobj["rightDocument"] = inputkeyword
						}
					}else{
						this.$message.error("请在关键词选择标题/摘要/权利要求书(可多选)")
						return;
					}
				}
			}
			//关键词是否填写
			this.formobj["applicant"] = null //申请（专利权）人
			this.formobj["inventor"] = null//发明（设计）人
			this.formobj["obligee"] = null//专利权人
			this.formobj["assignee"] = null //受让人
			this.formobj["transferor"] = null //转让人
			if(this.mingchengVaule.length>0){
				var inputmingcheng = []
				for(let i=0;i<this.mingchengVaule.length;i++){
					if(this.mingchengVaule[i].value){
						inputmingcheng.push(this.mingchengVaule[i].value)
					}
				}
				if(this.mingchengVaule.length>1&&inputmingcheng.length<this.mingchengVaule.length){
					this.$message.error("请输入内容到名称的所有输入框")
					return;
				}
				if(inputmingcheng.length==this.mingchengVaule.length){
					if(this.mingchengType.length>0){
						if(this.mingchengType.indexOf("申请人")>-1){
							this.formobj["applicant"] = inputmingcheng
						}
						if(this.mingchengType.indexOf("发明(设计)人")>-1){
							this.formobj["inventor"] = inputmingcheng
						}
						if(this.mingchengType.indexOf("专利权人")>-1){
							this.formobj["obligee"] = inputmingcheng
						}
						if(this.mingchengType.indexOf("受让人")>-1){
							this.formobj["assignee"] = inputmingcheng
						}
						if(this.mingchengType.indexOf("转让人")>-1){
							this.formobj["transferor"] = inputmingcheng
						}
					}else{
						this.$message.error("请在名称选择申请人/发明(设计)人/专利权人/受让人/转让人(可多选)")
						return;
					}
				}
			}
			//代理是否填写
			this.formobj["agency"] = null
			this.formobj["agent"] = null //代理人
			if(this.dailiVaule){
				if(this.dailiType.length>0){
					if(this.dailiType.indexOf("代理机构")>-1){
						this.formobj["agency"] = this.dailiVaule
					}
					if(this.dailiType.indexOf("代理人")>-1){
						this.formobj["agent"] = this.dailiVaule
					}
				}else{
					this.$message.error("请在代理选择代理机构/代理人(可多选)")
					return;
				}
			}
			//地址是否填写
			this.formobj["applyAddress"] = null //申请人地址
			this.formobj["applyCountry"] = null //	申请人国家
			this.formobj["openCountry"] = null //公开国
			if(this.addressVaule){
				if(this.addressType.length>0){
					if(this.addressType.indexOf("申请人地址")>-1){
						this.formobj["applyAddress"] = this.addressVaule
					}
					if(this.addressType.indexOf("申请人国家")>-1){
						this.formobj["applyCountry"] = this.addressVaule
					}
					if(this.addressType.indexOf("公开国")>-1){
						this.formobj["openCountry"] = this.addressVaule
					}
				}else{
					this.$message.error("请在地址选择申请人地址/申请人国家/公开国(可多选)")
					return;
				}
			}
			//分类号是否填写
			this.formobj["classNo"] = [] //分类号
			if(this.fenleiVaule.length>0){
				var inputfenlei = []
				for(let i=0;i<this.fenleiVaule.length;i++){
					if(this.fenleiVaule[i].value){
						inputfenlei.push(this.fenleiVaule[i].value)
					}
				}
				if(this.fenleiVaule.length>1&&inputfenlei.length<this.fenleiVaule.length){
					this.$message.error("请输入内容到分类号的所有输入框")
					return;
				}
				this.formobj["classNo"] = inputfenlei //分类号
			}
			
			this.currentPage = 1
			this.getdataList()
		},
		//展开收起摘要
		handleShowDesc(index){
			var isShowDesc = this.dataList[index].isShowDesc
			this.dataList[index].isShowDesc = !isShowDesc
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getdataList()
		},
		//获取(文章)获得知识产权法律
		getdataList() {
			var _this = this;
			var params = this.formobj
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			console.log(params)
			this.$http.post('frontEnd/patent/pageInfo', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						//收起摘要
						item["isShowDesc"] = false
						item["classNoList"] = []
						if(item.classNo){
							try{
								item["classNoList"] = JSON.parse(item.classNo)
							}catch(e){}
						}
						// if(item.contents){
						// 	item["body"] = _this.$util.filtersText(item.contents,318)
						// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						// }
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//增加关键词搜索框
		addkeyword(){
			var obj = {
				value:""
			}
			this.keywordVaule.push(obj)
		},
		//删除关键词搜索框
		delkeyword(index){
			this.keywordVaule.splice(index,1)
		},
		//增加名称搜索框
		addmingcheng(){
			var obj = {
				value:""
			}
			this.mingchengVaule.push(obj)
		},
		//删除名称搜索框
		delmingcheng(index){
			this.mingchengVaule.splice(index,1)
		},
		//增加分类搜索框
		addfenlei(){
			var obj = {
				value:""
			}
			this.fenleiVaule.push(obj)
		},
		//删除分类搜索框
		delfenlei(index){
			this.fenleiVaule.splice(index,1)
		},
		//获取主分类号
		pageMainClassNo() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888,
			};
			this.$http.post('frontEnd/patent/pageMainClassNo', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						for(var i=0;i<records.length;i++){
							records[i]["value"] = records[i].mainClassNo
							records[i]["label"] = records[i].mainClassNo+records[i].name
						}
						_this.fenleiList  = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
